<script lang="ts" setup>
defineProps<{
  claimId?: string;
  internal?: boolean | null;
  size?: "xs" | "sm" | "default";
  class?: string;
}>();
const { mutate, isPending } = useClaimInternalUpdate();
const hasAccess = useHasAccess();
</script>
<template>
  <BasicButton
    v-if="hasAccess.editor"
    :class="cn('relative leading-0 text-color-5 [&>span]:absolute', $props.class)"
    :size="size || 'sm'"
    :variant="!internal ? 'black' : 'default'"
    :text="internal ? 'claim.scope.icon.editorial' : 'claim.scope.icon.public'"
    :tooltip="internal ? 'claim.scope.tooltip.editorial' : 'claim.scope.tooltip.public'"
    :loading="isPending"
    @click.prevent.stop="() => mutate({ id: claimId, internal: !internal })"
  />
</template>
