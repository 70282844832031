<script lang="ts" setup>
import { Icon } from "@iconify/vue";

const props = defineProps<{
  date?: string | null;
  user?: string;
  icon: string;
  minimized?: boolean;
  tooltip?: string;
}>();
</script>

<template>
  <BasicTooltip :disabled="!tooltip" side="left">
    <template #trigger>
      <TooltipTrigger asChild>
        <div
          v-if="date"
          :class="
            cn('overflow-hidden text-color-5 text-xs tracking-wide flex items-center gap-1.5 leading-[1]', {
              'text-sm gap-2': !props.minimized
            })
          "
        >
          <Icon :icon="icon" :class="cn('text-color-5 shrink-0', {})" />
          <p class="grow overflow-hidden text-ellipsis whitespace-nowrap">
            <Date :date="date" />
            <span v-if="user">
              {{ " " }}{{ $t("createdUpdated.by") }}
              {{ user }}
            </span>
          </p>
        </div>
      </TooltipTrigger>
    </template>
    <template v-if="tooltip" #content>
      {{ $t(tooltip) }}
    </template>
  </BasicTooltip>
</template>
